<script setup lang="ts">
import { UiIcon } from './Icon';

const props = withDefaults(
	defineProps<{
		size: 'xs' | 'sm' | 'base' | 'l' | 'xl';
		withText?: boolean;
		text?: string;
		code?: string;
	}>(),
	{
		withText: false,
		text: '',
		code: '',
	},
);

const iconClasses = computed(() => ({
	'text-[24px]': props.size === 'xs',
	'text-[32px]': props.size === 'sm',
	'text-[48px]': props.size === 'base',
	'text-[64px]': props.size === 'l',
	'text-[96px]': props.size === 'xl',
}));

const classes = computed(() => ({
	'flex items-center text-gray-400': true,
	'gap-2 text-xs': props.size === 'xs',
	'gap-[9px] text-2xl': props.size === 'sm',
	'gap-4 text-4xl': props.size === 'base',
	'gap-4 text-6xl': props.size === 'l',
	'gap-5 text-8xl': props.size === 'xl',
}));
</script>

<template>
	<div :class="classes">
		<UiIcon
			:name="`filled--flags--${(code).toLowerCase()}`"
			:class="iconClasses"
			filled
		/>

		<span
			v-if="withText && text"
			class="leading-tight font-normal"
		>{{ text }}</span>
	</div>
</template>
